import React, { useEffect, useState } from "react";
import { get } from "../Api";
import { Link } from "react-scroll";

export default function Header() {
  const [qualities, setQualities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getLogo();
        console.log("Алынган маалымат:", response);

        setQualities(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div id="header">
    <div className="container">
      <div className="header">
      {qualities.map((el) => (
            <img src={el.logo} className="logo" />
          ))}
        <div className="pages">
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
            activeClass="active-link"
          >
            О нас
          </Link>
          <Link
            to="service"
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
            activeClass="active-link"
          >
            Наши сервисы
          </Link>
          <Link
            to="prices"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            activeClass="active-link"
          >
            Цены
          </Link>
          <Link
            to="faq"
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
            activeClass="active-link"
          >
            Faq
          </Link>
        </div>

        <a target="_blank" href="https://wa.me/996700178874">
          <button className="btn">Связаться</button>
        </a>
      </div>
    </div>
  </div>
  );
}
