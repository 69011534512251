import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { VscSearchFuzzy } from "react-icons/vsc";
import { FaCalendarCheck } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import Sale from "./Sale";
import { get } from "../Api";

export default function PlanWork() {
  const [qualities, setQualities] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getPlan();
        console.log("Алынган маалымат:", response);

        setQualities(response); 
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData(); 
  }, []); 
  return (
    <div className="container">
        <h1 className="title">Схема работы</h1>
      <div className="steps">
        {qualities.map((step, index) => (
         
            <div key={index} className="step">
              <div className="step-header">
                <span>{step.step} </span>
              </div>
              <div className="step-body">
                <img src={step.icon} className="icon"/>
                <h2 className="step-title">{step.title}</h2>
                <p className="step-description">{step.description}</p>
              </div>
            </div>
        ))}
      </div>
      <Sale />
    </div>
  );
}

