import React, { useEffect, useState } from "react";
import { get } from "../Api";

export default function Service() {
  const [qualities, setQualities] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getService();
        console.log("Алынган маалымат:", response);

        setQualities(response); 
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData(); 
  }, []); 
  return (
    <div id="service">
      <div className="container">
        <div className="service">
          <h1 className="title red">
            Сервис <span>REMONTTECH.KG </span>предлагает лучшие цены
          </h1>
          <div className="service-cards">
            {qualities.map((el) => (
                <div className="servie-card">
                  <div>
                    <h3>{el.type_of_service}</h3>
                    <p>{el.description}</p>
                  </div>
                  <h5>От {el.price}</h5>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
