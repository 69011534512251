import React, { useEffect, useState } from "react";
import { FaTelegramPlane, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { get } from "../Api";

export default function Footer() {
  const [contactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getContact();
        console.log("Алынган маалымат:", response);
        setContactInfo(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  const [contact, setContac] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getIcons();
        console.log("Алынган маалымат:", response);
        setContac(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        {contactInfo.length > 0 && (
          <div className="footer-section">
            <h3>Контакты</h3>
            <p>
              Телефон:{" "}
              <a href={`tel:${contactInfo[0].phone_number}`}>
                {contactInfo[0].phone_number}
              </a>
            </p>
            <p>
              Email:{" "}
              <a href={`mailto:${contactInfo[0].email}`}>
                {contactInfo[0].email}
              </a>
            </p>
            <p>{contactInfo[0].address}</p>
          </div>
        )}

        <div className="footer-section">
          <h3>Социальные сети</h3>

          <div className="contsxt">
       {contact.map((el) => (
          
              <div className="cons">
                <a
                className="cons"
                  href={el.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 <img className="iconsd_footer" src={el.icon} alt="" />
                </a>
            </div>
          ))}
       </div>
        </div>
        {contactInfo.length > 0 && contactInfo[0].address_iframe && (
          <div className="footer-section">
            <h3>Мы на карте</h3>
            <div
              className="map-container"
              dangerouslySetInnerHTML={{
                __html: contactInfo[0].address_iframe,
              }}
            />
          </div>
        )}
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Все права защищены. Компания "Примерная"</p>
      </div>
    </footer>
  );
}
