import React, { useEffect, useState } from "react";
import { get } from "../Api";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "ПОЧЕМУ МОЯ БЫТОВАЯ ТЕХНИКА СЛОМАЛАСЬ?",
      answer:
        "Причины поломок бытовой техники могут быть разными: от естественного износа до неправильного использования. Часто причиной является отсутствие регулярного ухода и обслуживания.",
    },
    {
      question: "КАКИЕ ПРИЗНАКИ ТОГО, ЧТО ТЕХНИКА СКОРО СЛОМАЕТСЯ?",
      answer:
        "Некоторые признаки включают странные звуки, замедленную работу, перегрев или периодическое отключение устройства. В таких случаях рекомендуется немедленно обратиться в сервисный центр.",
    },
    {
      question: "КАК ЧАСТО НУЖНО ПРОВОДИТЬ ОБСЛУЖИВАНИЕ БЫТОВОЙ ТЕХНИКИ?",
      answer:
        "Рекомендуется проводить техническое обслуживание хотя бы раз в год для большинства бытовых приборов, таких как стиральные машины, холодильники и кондиционеры.",
    },
    {
      question: "МОГУ ЛИ Я САМ РЕМОНТИРОВАТЬ БЫТОВУЮ ТЕХНИКУ?",
      answer:
        "Некоторые простые задачи, такие как очистка фильтров, можно выполнить самостоятельно. Однако для сложных поломок лучше доверить ремонт профессионалам, чтобы избежать дополнительных повреждений.",
    },
    {
      question: "СКОЛЬКО СТОИТ РЕМОНТ БЫТОВОЙ ТЕХНИКИ?",
      answer:
        "Стоимость ремонта зависит от типа поломки и сложности работы. Небольшие ремонты могут быть недорогими, но замена основных компонентов может обойтись дороже.",
    },
  ];
  
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [qualities, setQualities] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getFaq();
        console.log("Алынган маалымат:", response);

        setQualities(response); 
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData(); 
  }, []); 
  return (
    <div className="faq-container">
     
        <h1 className="title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h1>
      <div className="faq-list">
        {qualities.map((item, index) => (
            <div key={index} className="faq-item">
              <button
                className={`faq-question ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                {item.question}
                <span className="arrow"></span>
              </button>
              <div
                className={`faq-answer ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                {item.answer}
              </div>
            </div>
        ))}
      </div>
    </div>
  );
}
