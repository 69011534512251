import React from "react";
import sale from "./../assets/images/remont-tehniki.png";
export default function Sale() {
  return (
    <div className="sale">
      <div className="sales">
        <h2>Получите скидку 15% прямо сейчас!</h2>
        <p>
          Вы выбираете LG, а мы в знак благодарности дарим Вам скидку 15% на все
          виды ремонтных работ в нашем центре!
        </p>
        <a target="_blank" href="https:/wa.me/996700178874">
          <button className="btn">Получить скидку 15%</button>
        </a>
      </div>
      <img src={sale} alt="" />
    </div>
  );
}
