import React, { useEffect, useState } from "react";
import tehno from "./../assets/images/remont-elektroniki.png"; // Путь к изображению
import { get } from "../Api";

export default function Section() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [qualities, setQualities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get.getSlider();
        console.log("Алынган маалымат:", data);

        setTitle(data[0]?.title || "");
        setDescription(data[0]?.description || "");
        setImage(data[0]?.image || "");
        setQualities(data[0]?.qualities || []);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      {title ? (
        <>
          <div className="section">
            <div className="remonts">
              <h1>{title}</h1>
              <p>{description}</p>
              <a target="_blank" href="tel:996700178874">
                <button className="btn">Вызвать мастера</button>
              </a>
            </div>
            {image && <img className="teh" src={image} alt="Remont" />}
          </div>
        </>
      ) : (
        <p>Маалымат жүктөлүүдө...</p>
      )}
    </div>
  );
}
