import "./App.css";
import "./assets/styles/media.css";
import About from "./Components/About";
import Header from "./Components/Header";
import PlanWork from "./Components/PlanWork";
import Section from "./Components/Section";
import Service from "./Components/Service";
import Cause from "./Components/Сause";
import Faq from "./Components/Faq";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";
import { get } from "./Api";
import Whatsapp from "../src/assets/images/Whatsapp.png";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { Element } from "react-scroll";

function App() {
  const [showIcons, setShowIcons] = useState(true);
  const [qualities, setQualities] = useState([]);
  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await get.getLogo();
        const { favicon } = response[0];

        const linkElement = document.querySelector("link[rel='icon']");
        if (linkElement) {
          linkElement.href = favicon;
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchLogoData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getNumber();
        console.log("Алынган маалымат:", response);

        setQualities(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="App">
      <div className="App">
        {qualities.map((el) => (
          <div key={el.phone_number}>
            {showIcons && (
              <div className="icon-container">
                <div className="icon icon2">
                  <a href={`tel:${el.phone_number}`} target="_blank">
                    <FaPhoneAlt size={23} color="#fff" />
                  </a>
                </div>
                <div className="icon icon3">
                  <a
                    href={el.wa_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={25} color="#fff" />
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <Header />
      <Section />
      <Element name="about">
        <About />
      </Element>
      <Element name="service">
        <PlanWork />
      </Element>
      <Element name="prices">
        <Service />
      </Element>
      <Element name="faq">
        <Faq />
      </Element>

      <Footer />
    </div>
  );
}

export default App;
