import React, { useEffect, useState } from "react";
import { get } from "../Api";

export default function About() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [qualities, setQualities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get.getSlider();
        console.log("Алынган маалымат:", data);

        setTitle(data[0]?.title || "");
        setDescription(data[0]?.description || "");
        setImage(data[0]?.image || "");
        setQualities(data[0]?.qualities || []); 
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="about">
        <h1 className="title">ПОЧЕМУ ВЫБИРАЮТ НАС</h1>
        <div className="about-card">
          {qualities.length > 0 ? (
            qualities.map((quality, index) => (
              <div className="card" key={index}>
                <img src={quality.icon} alt={quality.title} className="icon" />
                <h3>{quality.title}</h3>
                <p>{quality.description}</p>
              </div>
            ))
          ) : (
            <p>Данные не найдены.</p> 
          )}
        </div>
      </div>
    </div>
  );
}
