import axios from "axios";

export const api = axios.create({
    baseURL: "https://remonttech.kg/api/v1/",
  });

  
export const get = {
    getSlider: () => {
      return api
        .get(`about_us/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getFuntion: () => {
      return api
        .get(`about_malfunction`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getService: () => {
      return api
        .get(`services`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getPlan: () => {
      return api
        .get(`scheme_of_work/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getFaq: () => {
      return api
        .get(`faq/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getContact: () => {
      return api
        .get(`contacts/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getIcons: () => {
      return api
        .get(`social_media/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getLogo: () => {
      return api
        .get(`logos/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
    getNumber: () => {
      return api
        .get(`link_number/`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching slider items:", error);
          throw error;
        });
    },
  };
  
